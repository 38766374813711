.slider-clients {
    margin-bottom: 120px;
	margin-top: 300px;

    &__container {
		position: relative;
        max-width: 1200px;
        margin: 0 auto;
    }


    .slide {
        // display: flex;
        // align-items: center;
        // height: 99px;
		img {
			max-width: 100%;
			max-height: 100%;
		}
    }

    .swiper-button {
        &-prev, &-next {
			position: absolute;
			z-index: 10;
            width: 20px;
            height: 20px;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			border: none;
			background: transparent;
			cursor: pointer;
			&::after {
				min-width: 100%;
				height: 100%;
				border-top: 2px solid red;
				border-left: 2px solid red;
				content: '';
				transform: rotate(-40deg);

			}
        }

        &-prev {
			left: 20px;
		}
        &-next {
            transform: translateY(-50%) rotate(175deg);
			right: 20px;
        }
    }

}



