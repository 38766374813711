* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    object-fit: cover;
}

@import "_sliders/swiper.min";
// @import "_sliders/1slide";
// @import "_sliders/1.Default";
@import "_sliders/clients";
// @import "_sliders/video-slider";



